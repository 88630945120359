import React, { useEffect } from 'react';
import { loadStripe } from '@stripe/stripe-js';

// Make sure to call `loadStripe` outside of a component’s render to avoid
// recreating the `Stripe` object on every render.
const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_KEY);

function useQuery() {
  return new URLSearchParams(window.location.search);
}


function Checkout() {

    let query = useQuery();

    useEffect(() => {
      handleLoad();
    });
  
    const handleLoad = async (event) => {
      // Get Stripe.js instance
      const stripe = await stripePromise;
  
      console.log(query.get("amount"));
  
      // Call your backend to create the Checkout Session
      const response = await fetch(process.env.REACT_APP_CHECKOUT_API_URL, {
        method: 'POST',
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({ "amount": query.get("amount") })
      });
  
      const session = await response.json();
  
      // When the customer clicks on the button, redirect them to Checkout.
      const result = await stripe.redirectToCheckout({
        sessionId: session.id,
      });
  
      if (result.error) {
        // If `redirectToCheckout` fails due to a browser or network
        // error, display the localized error message to your customer
        // using `result.error.message`.
      }
    };
  
    return (
      <div className="body">
        <div className="lds-ripple">
          <div></div>
          <div></div>
        </div>
      </div>
    );
}

export default Checkout
