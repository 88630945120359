import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter as Router, Switch, Route} from "react-router-dom";
import './index.css';
// import App from './App';
import Home from './pages/Home';
import Checkout from './pages/Checkout';
import SuccessPayment from './pages/SuccessPayment';
import ErrorPayment from './pages/ErrorPayment';

const Routing = () => {
  return(
    <Router>
      <Switch>
        <Route exact path="/" component={Home} />
        <Route path="/checkout" component={Checkout} />
        <Route path="/success-payment" component={SuccessPayment} />
        <Route path="/error-payment" component={ErrorPayment} />
      </Switch>
    </Router>
  )
}

ReactDOM.render(
  <React.StrictMode>
    <Routing />
  </React.StrictMode>,
  document.getElementById('root')
);